<template>
  <form @submit.prevent="onsubmit">
    <va-input
      v-model="email"
      type="email"
      :label="$t('auth.email')"
      :error="!!emailErrors.length"
      :error-messages="emailErrors"
    />

    <va-input
      v-model="password"
      type="password"
      :label="$t('auth.password')"
      :error="!!passwordErrors.length"
      :error-messages="passwordErrors"
    />
    <div class="d-flex justify--center mt-3">
      <va-button type="submit" class="my-0">{{ $t('auth.login') }}</va-button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { login } from '../../../apollo/api/auth';

export default {
  name: 'login',
  data() {
    return {
      email: '',
      password: '',
      emailErrors: [],
      passwordErrors: [],
    };
  },
  computed: {
    formReady() {
      return !this.emailErrors.length && !this.passwordErrors.length;
    },
    ...mapGetters(['token', 'user']),
  },
  methods: {
    ...mapMutations(['setToken', 'setUser']),
    async onsubmit() {
      this.emailErrors = this.email ? [] : ['Email is required'];
      this.passwordErrors = this.password ? [] : ['Password is required'];
      if (!this.formReady) {
        return;
      }

      try {
        const response = await login(this.email, this.password);
        this.setToken(response.login.jwtToken);
        this.setUser({ ...response.login });
        this.showToast('Login successful', {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        this.$router.push({ name: 'dashboard' });
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
      }
    },
  },
};
</script>

<style lang="scss"></style>
